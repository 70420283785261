/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { CheckOutlined, RightOutlined } from '@ant-design/icons';

import { CUSTOMER_TYPE_TOGGLE_STYLE, LANGUAGE, LANG_CLASS_NAME, EMPTY_STRING } from '../constants/appConstants';

import '../assets/css/Newcustomer.css';

const NewCustomerForm = ({
  newCustomerLabelTranslation,
  language,
  setLanguage,
  newCustomerModalVisible,
  setNewCustomerModalVisible,
  setCloseConfirmationVisible,
  setCustomerModalVisible,
  isCreditTerms,
  handleIsCreditTerms,
  isSyscoRep,
  handleIsSyscoRep,
}) => {
  const handleCustomerTypeToggle = (customerTypeSelected) => {
    if (customerTypeSelected) {
      return CUSTOMER_TYPE_TOGGLE_STYLE?.SELECTED;
    }
    return CUSTOMER_TYPE_TOGGLE_STYLE?.NOT_SELECTED;
  };

  const handleNewCustomer = (e) => {
    e.preventDefault();
    if (isCreditTerms || isSyscoRep) {
      setNewCustomerModalVisible(false);
      setCustomerModalVisible(true);
    }
  };

  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={newCustomerModalVisible}
      onOk={() => setNewCustomerModalVisible(false)}
      onCancel={() => setCloseConfirmationVisible(true)}
      width={1200}
      footer={null}
      maskClosable={false}
      keyboard={false}
    >
      <Row className="model-body">
        <Col xs={16} sm={16} md={18} lg={20} xl={20} className="model-header">
          <p className="newcustomer-heading" key="new_customer_become_customer">
            {newCustomerLabelTranslation?.new_customer_become_customer}
          </p>
        </Col>
        <Col xs={8} sm={8} md={6} lg={4} xl={4} className="model-header">
          <span className="Language-selection-newcustomer-withoutstep">
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => setLanguage(LANGUAGE.ENGLISH)}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => setLanguage(LANGUAGE.FRENCH)}
            >
              FR
            </a>
          </span>
        </Col>
        <Col span={24} className="h1-letsgetstarted-wrap">
          <h4 className="h1-letsgetstarted">{newCustomerLabelTranslation?.new_customer_lets_get_started}</h4>
        </Col>
        <Col xs={24} sm={24} md={24} lg={11} xl={12} className="col-left-newcustomer">
          <Col span={24} className="">
            <p className="p-newcustomer">{newCustomerLabelTranslation?.new_customer_self_serve_options}</p>
            <div
              className={isCreditTerms ? 'Newcustomer-option1 Newcustomer-option1-selected' : 'Newcustomer-option1'}
              id="credit-term"
              onClick={(e) => handleIsCreditTerms(e)}
            >
              {' '}
              <CheckOutlined className={handleCustomerTypeToggle(isCreditTerms)} />
              <p className="option2-title">{newCustomerLabelTranslation?.new_customer_set_up_credit_terms}</p>
            </div>
            <div className="Newcustomer-option-businesspartner">
              <p className="business-partner-text">
                {newCustomerLabelTranslation?.new_customer_sysco_partner_contact_you}
              </p>
            </div>
            <div
              className={isSyscoRep ? 'Newcustomer-option1 Newcustomer-option1-selected' : 'Newcustomer-option1'}
              onClick={(e) => handleIsSyscoRep(e)}
            >
              <Row>
                <Col span={2}>
                  <CheckOutlined className={handleCustomerTypeToggle(isSyscoRep)} />
                </Col>
                <Col span={22} id="associate-help">
                  <p className="option3-title">{newCustomerLabelTranslation?.new_customer_select_option_sysco_repo}</p>
                  <p onClick={(e) => handleIsSyscoRep(e)}>
                    {newCustomerLabelTranslation?.new_customer_select_option_sysco_repo_msg}
                  </p>
                </Col>
              </Row>
            </div>
            <Button className="catelog-btn newcustomer-btn-continue" type="primary" onClick={handleNewCustomer}>
              {newCustomerLabelTranslation?.new_customer_continue}
              <RightOutlined />
            </Button>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={24} lg={11} xl={12} className="col-rightside-newcustomer">
          <Col span={24} className="col-rightside-newcustomer-box">
            <h1 className="newcustomer-rightside-text">
              {newCustomerLabelTranslation?.new_customer_benefits_of_an_account}
            </h1>
            <Row>
              <Col span={3} className="">
                <img src={require('../assets/Images/qa.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{newCustomerLabelTranslation?.new_customer_quality_assurance}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="">
                {' '}
                <img src={require('../assets/Images/cs.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{newCustomerLabelTranslation?.new_customer_customer_service}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="">
                {' '}
                <img src={require('../assets/Images/ont.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{newCustomerLabelTranslation?.new_customer_ordering_inventory}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="">
                {' '}
                <img alt="syscoadvantage" src={require('../assets/Images/syscoadvantage.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{newCustomerLabelTranslation?.new_customer_sysco_advantage}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="">
                {' '}
                <img alt="menu" src={require('../assets/Images/menu.png')} />
              </Col>
              <Col span={21} className="">
                {' '}
                <p>{newCustomerLabelTranslation?.new_customer_menu_marketing_services}</p>
              </Col>
              <Col span={24} className="">
                <hr className="menu-divider"></hr>
              </Col>
            </Row>
            <span>
              <p className="qesstion-call">{newCustomerLabelTranslation?.new_customer_questions}</p>
              <a> {newCustomerLabelTranslation?.new_customer_call} </a>
            </span>
          </Col>
        </Col>
      </Row>
    </Modal>
  );
};

export default NewCustomerForm;
