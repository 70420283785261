import { Row, Col, Modal, Button } from 'antd';
import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import '../assets/css/BecomeACustomerComplete.css';
import { SYSCO_CANADA_URLS, LANG_CLASS_NAME, LANGUAGE, EMPTY_STRING, CUSTOMER_TYPES } from '../constants/appConstants';

const Complete = ({
  completeLabelTranslation,
  language,
  setLanguage,
  onboardingCompletedModalVisible,
  setOnboardingCompletedModalVisible,
  hasWorkedWithSyscoRep,
  isCreditTerms,
  isSyscoRep,
  setIsCreditTerms,
  setIsSyscoRep,
  setCustomerType,
}) => {
  const closeCompleteModal = () => {
    setOnboardingCompletedModalVisible(false);
  };

  const clearCustomerType = () => {
    setCustomerType(CUSTOMER_TYPES?.SYSCO_ASSOCIATE_HELP);
    setIsCreditTerms(false);
    setIsSyscoRep(true);
  };

  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={onboardingCompletedModalVisible}
      onOk={() => setOnboardingCompletedModalVisible(false)}
      onCancel={() => closeCompleteModal()}
      width={1200}
      footer={null}
      maskClosable={false}
      keyboard={false}
      afterClose={() => clearCustomerType()}
    >
      <Row className="model-body">
        <Col xs={16} sm={16} md={20} lg={20} xl={20} className="model-header">
          <p className="newcustomer-heading">{completeLabelTranslation?.steps_become_a_customer_default}</p>
        </Col>
        <Col xs={8} sm={8} md={4} lg={4} xl={4} className="model-header">
          <span className="Language-selection-newcustomer-withoutstep">
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => setLanguage(LANGUAGE.ENGLISH)}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => setLanguage(LANGUAGE.FRENCH)}
            >
              FR
            </a>
          </span>
        </Col>

        <Col span={24} className="col-leftside-becomeacustomer-complte">
          <h1 className="register">{completeLabelTranslation?.complete_header}</h1>
          {!isCreditTerms && isSyscoRep ? (
            <h3 className="register">{completeLabelTranslation?.complete_sales_rep_thank_you}</h3>
          ) : null}
          <h3 className="register">{completeLabelTranslation?.complete_you_are_there}</h3>
          <p className="register">{completeLabelTranslation?.complete_next_steps}</p>
          <div>
            <ul>
              <li className="register">{completeLabelTranslation?.complete_team_member_connect_msg}</li>
              {!isCreditTerms && isSyscoRep ? (
                <li className="register">{completeLabelTranslation?.complete_learn_sales_rep_msg}</li>
              ) : null}
            </ul>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default Complete;
