/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Row, Col, Modal, Button, Steps, Form, Select } from 'antd';
import _ from 'lodash';
import { createCustomerBusiness, getSegments } from '../actions';
import { EMPTY_STRING, LANGUAGE, LANG_CLASS_NAME } from '../constants/appConstants';
import Error from '../components/error';

import '../assets/css/YourBusiness.css';

const LOADING_DEFAULT_LATENCY = process.env.REACT_APP_LOADING_DEFAULT_LATENCY;

const { Step } = Steps;

const BusinessForm = ({
  businessLabelTranslation,
  language,
  setLanguage,
  onboardingBusinessModalVisible,
  setOnboardingBusinessModalVisible,
  setCloseConfirmationVisible,
  isSyscoRep,
  billingHeaderVisible,
  setSelectedIndustryKey,
  setSegmentVisible,
  setSubSegmentVisible,
  setSelectedIndustry,
  setCustomerModalVisible,
  segmentBusinessId,
  customerUserId,
  isModalClosed,
  setOnboardingShippingModalVisible,
  setOnboardingCompletedModalVisible,
  clearOnBoardingFormData,
  selectedIndustryKey,
  segmentVisible,
  subSegmentVisible,
  subSegmentTypes,
  setSegmentBusinessId,
  selectedIndustry,
  setSubSegmentTypes,
  businessResError,
  setBusinessLoadingState,
  businessLoadingState,
  form,
  getFieldDecorator,
}) => {
  const dispatch = useDispatch();

  const segmentNameTile = businessLabelTranslation?.business_segment;
  const subSegmentNameTile = businessLabelTranslation?.business_sub_segment;
  const segmentRastaurant = 'restaurant';

  const [segmentTitle, setSegmentTitle] = useState(segmentNameTile);
  const [segmentTypes, setSegmentTypes] = useState([]);
  const [segmentTypesImage, setSegmentTypesImage] = useState([]);
  const [segmentRestaurantTypes, setSegmentRestaurantTypes] = useState([]);

  const [isIndusturyError, setIsIndusturyError] = useState(false);
  const [industuryErrorMsg, setIndusturyErrorMsg] = useState(EMPTY_STRING);

  useEffect(() => {
    dispatch(getSegments(language));
  }, [dispatch, language]);

  const {
    data: { items: segmentData },
  } = useSelector((state) => state.segments);

  useEffect(() => {
    if (segmentData) {
      const uniqueSegments = [];
      const uniqueSegmentsImage = [];
      segmentData.map((segment) => {
        if (uniqueSegments.indexOf(segment?.type?.trim().toLowerCase()) === -1) {
          if (segment?.type?.trim().toLowerCase() === segmentRastaurant) {
            uniqueSegments.push(segment?.type?.trim().toLowerCase());
          } else {
            uniqueSegments.push(segment?.name?.trim().toLowerCase());
          }
          uniqueSegmentsImage.push(segment?.imageUrl?.trim().toLowerCase());
        }
        return uniqueSegments;
      });
      setSegmentTypes(uniqueSegments);
      setSegmentTypesImage(uniqueSegmentsImage);
      const segmentNames = segmentData
        .filter((segment) => segment.type?.trim().toLowerCase() === segmentRastaurant)
        .map((restSegments) => restSegments?.name);
      setSegmentRestaurantTypes(segmentNames);
    }
  }, [segmentData]);

  useEffect(() => {
    if (selectedIndustry !== EMPTY_STRING) {
      const segmentNames = segmentData.filter((segment) => segment.name?.trim().toLowerCase() === selectedIndustry);
      setSubSegmentTypes(segmentNames && segmentNames[0]?.segments);
    }
  }, [selectedIndustry]);

  const capitaliaze = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const validateFieldValue = (fieldName) => {
    form.validateFields([fieldName], (errors) => {
      if (errors) {
        form.resetFields([fieldName]);
      }
    });
  };

  const validateAllField = () => {
    validateFieldValue('SegmentSelVal');
    validateFieldValue('SubSegmentSelVal');
  };

  const clearFieds = () => {
    setIsIndusturyError(false);
    setIndusturyErrorMsg(EMPTY_STRING);
    validateAllField();
  };

  const handleSubSegmentOnChange = (businessRef) => {
    clearFieds();
    setSegmentBusinessId(businessRef);
  };

  const handleIndustryType = (e, segment) => {
    e.preventDefault();

    clearFieds();
    setSegmentBusinessId(0);
    form.setFieldsValue({
      SegmentSelVal: undefined,
    });
    setSelectedIndustryKey(segment);

    if (segment.trim().toLowerCase() === segmentRastaurant) {
      setSelectedIndustry(EMPTY_STRING);
      setSegmentTitle(subSegmentNameTile);
      setSegmentVisible(true);
      setSubSegmentVisible(false);
    } else {
      setSegmentTitle(segmentNameTile);
      setSegmentVisible(false);
      setSubSegmentVisible(true);
      const segmentName =
        segment.trim().toLowerCase() === segmentRastaurant
          ? segmentData.filter((s) => s.type?.trim().toLowerCase() === segment.trim().toLowerCase()).map((s) => s.name)
          : segmentData.filter((s) => s.name?.trim().toLowerCase() === segment.trim().toLowerCase()).map((s) => s.name);
      setSelectedIndustry(segmentName && segmentName[0].trim().toLowerCase());
    }
  };

  const handleSegmentOnChange = (segment) => {
    clearFieds();
    setSelectedIndustry(segment.trim().toLowerCase());
    setSubSegmentVisible(true);
    form.setFieldsValue({
      SegmentSelVal: undefined,
    });
  };

  const handleCustomerBusinessBackButton = (e) => {
    e.preventDefault();
    setOnboardingBusinessModalVisible(false);
    setCustomerModalVisible(true);
  };

  const businessResErrorRef = useRef(businessResError);

  const loadShippingAddressModal = _.debounce((businessResErrorRef) => {
    if (!businessResErrorRef.current) {
      setBusinessLoadingState(false);
      setOnboardingBusinessModalVisible(false);
      setOnboardingShippingModalVisible(true);
    }
  }, LOADING_DEFAULT_LATENCY);

  const loadCompleteModal = _.debounce((businessResErrorRef) => {
    if (!businessResErrorRef.current) {
      setBusinessLoadingState(false);
      setOnboardingCompletedModalVisible(true);
      setOnboardingBusinessModalVisible(false);
      clearOnBoardingFormData();
    }
  }, 3000);

  const handleCustomerBusinessSubmit = (e) => {
    e.preventDefault();

    if (selectedIndustryKey === EMPTY_STRING) {
      setIndusturyErrorMsg(businessLabelTranslation?.error_business_industury_msg);
      setIsIndusturyError(true);
    } else {
      setIndusturyErrorMsg(EMPTY_STRING);
      setIsIndusturyError(false);
    }

    form.validateFields(['SubSegmentSelVal', 'SegmentSelVal'], (err) => {
      if (!err) {
        if (segmentBusinessId !== 0 && !isIndusturyError) {
          const customerBusinessData = {
            userId: customerUserId,
            isModelClosed: isModalClosed,
            businessId: segmentBusinessId,
            lang: language,
          };
          setBusinessLoadingState(true);
          dispatch(createCustomerBusiness(customerBusinessData));
          loadCompleteModal(businessResErrorRef);
        }
      }
    });
  };

  useEffect(() => {
    businessResErrorRef.current = businessResError;
  }, [businessResError]);

  return (
    <Modal
      className="contactus-model"
      title=""
      centered
      visible={onboardingBusinessModalVisible}
      onOk={() => {
        setOnboardingBusinessModalVisible(false);
        clearFieds();
      }}
      onCancel={() => {
        setCloseConfirmationVisible(true);
        clearFieds();
      }}
      width={1200}
      footer={null}
      maskClosable={false}
      keyboard={false}
    >
      <Row className="model-body">
        <Col
          span={12}
          xs={24}
          sm={24}
          md={8}
          lg={8}
          xl={6}
          className={
            isSyscoRep
              ? 'model-header newcustomer-heading-become-desktop-wrap'
              : 'model-header newcustomer-heading-become-desktop-card '
          }
        >
          <p className="newcustomer-heading-become newcustomer-heading-business-desktop">
            {isSyscoRep
              ? businessLabelTranslation?.steps_become_a_customer_sales_ref
              : businessLabelTranslation?.steps_become_a_customer_default}
          </p>
        </Col>
        <Col
          span={isSyscoRep ? 7 : 12}
          xs={15}
          sm={15}
          md={12}
          lg={12}
          xl={14}
          className={isSyscoRep ? 'model-header ncob-steps sysco-rep-steps' : 'model-header ncob-steps'}
        >
          <Steps
            size="small"
            current={segmentBusinessId !== 0 && isSyscoRep ? 2 : 1}
            className={isSyscoRep ? 'sysco-rep-business' : 'credit-term'}
          >
            <Step title={businessLabelTranslation?.steps_about_you} />
            <Step title={businessLabelTranslation?.steps_your_business} />
            {!isSyscoRep ? (
              <Step
                title={businessLabelTranslation?.steps_shipping_address}
                className={segmentBusinessId !== 0 && isSyscoRep ? 'ant-steps-item-process-business' : null}
              />
            ) : null}
            {billingHeaderVisible && !isSyscoRep ? (
              <Step title={businessLabelTranslation?.steps_your_billing_address} />
            ) : null}
            {!isSyscoRep ? <Step title={businessLabelTranslation?.steps_shipping} /> : null}
            {!isSyscoRep ? <Step title={businessLabelTranslation?.steps_rewards_finish} /> : null}
          </Steps>
        </Col>
        <Col xs={9} sm={9} md={4} lg={4} xl={4} className="model-header">
          <span
            className={
              isSyscoRep
                ? 'syscorep-language Language-selection-newcustomer'
                : billingHeaderVisible
                ? 'Language-selection-newcustomer Language-selection-newcustomer-billing'
                : 'Language-selection-newcustomer Language-selection-newcustomer-without-billing'
            }
          >
            <a
              href="#!"
              className={language === LANGUAGE.ENGLISH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.ENGLISH);
                clearFieds();
              }}
            >
              EN
            </a>
            <span> I </span>
            <a
              href="#!"
              className={language === LANGUAGE.FRENCH ? LANG_CLASS_NAME : EMPTY_STRING}
              onClick={() => {
                setLanguage(LANGUAGE.FRENCH);
                clearFieds();
              }}
            >
              FR
            </a>
          </span>
        </Col>
        {!businessResError ? (
          <React.Fragment>
            <Col span={24} className="col-newcustomer">
              <Col span={24} className="industry-text">
                <p className="p-industry">{businessLabelTranslation?.business_industry}</p>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="col-leftside-yourbusiness">
                {segmentTypes &&
                  segmentTypes.length > 4 &&
                  segmentTypes?.slice(0, 4).map((segment, index) => {
                    return (
                      <div
                        key={segment}
                        className={
                          selectedIndustryKey !== segment ? 'industry-item' : 'industry-item industry-selected'
                        }
                        onClick={(e) => handleIndustryType(e, segment)}
                      >
                        {' '}
                        <span className="industry-icon-wrap">
                          <img src={segmentTypesImage[index]} alt="industry" />{' '}
                        </span>
                        {capitaliaze(segment)}
                      </div>
                    );
                  })}
                {segmentTypes &&
                  segmentTypes.length < 5 &&
                  segmentTypes?.map((segment, index) => {
                    return (
                      <div
                        key={segment}
                        className={
                          selectedIndustryKey !== segment ? 'industry-item' : 'industry-item industry-selected'
                        }
                        onClick={(e) => handleIndustryType(e, segment)}
                      >
                        {' '}
                        <span className="industry-icon-wrap">
                          <img src={segmentTypesImage[index]} alt="industry" />{' '}
                        </span>
                        {capitaliaze(segment)}
                      </div>
                    );
                  })}
              </Col>
              {segmentTypes && segmentTypes.length > 4 && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="col-leftside-yourbusiness">
                  {segmentTypes?.slice(4, segmentTypes.size).map((segment, index) => {
                    return (
                      <div
                        key={segment}
                        className={
                          selectedIndustryKey !== segment ? 'industry-item' : 'industry-item industry-selected'
                        }
                        onClick={(e) => handleIndustryType(e, segment)}
                      >
                        {' '}
                        <span className="industry-icon-wrap">
                          <img src={segmentTypesImage[index + 4]} alt="industry" />{' '}
                        </span>
                        {capitaliaze(segment)}
                      </div>
                    );
                  })}
                </Col>
              )}
              {isIndusturyError ? (
                <Col span={12} className="col-leftside-yourbusiness">
                  <span className="error-msg">{industuryErrorMsg}</span>
                </Col>
              ) : null}
            </Col>
            {segmentVisible && (
              <React.Fragment>
                <Col span={24} className="segment-text">
                  <p className="segment-lable">{businessLabelTranslation?.business_segment}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="segment-text">
                  <Form.Item className="segment-form">
                    {getFieldDecorator('SubSegmentSelVal', {
                      rules: [
                        {
                          required: selectedIndustry === EMPTY_STRING && segmentBusinessId === 0,
                          message: businessLabelTranslation?.error_business_segment_msg,
                        },
                      ],
                    })(
                      <Select
                        className="form-select"
                        placeholder={businessLabelTranslation?.business_select_option}
                        onChange={(value) => handleSegmentOnChange(value)}
                      >
                        {segmentRestaurantTypes.map((option) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </React.Fragment>
            )}
            {subSegmentVisible && (
              <React.Fragment>
                <Col span={24} className="segment-text">
                  <p className="segment-lable">{segmentTitle}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="segment-text">
                  <Form.Item className="segment-form">
                    {getFieldDecorator('SegmentSelVal', {
                      rules: [
                        {
                          required: true,
                          message:
                            segmentTitle === subSegmentNameTile
                              ? businessLabelTranslation?.error_business_sub_segment_msg
                              : businessLabelTranslation?.error_business_segment_msg,
                        },
                      ],
                    })(
                      <Select
                        className="form-select"
                        placeholder={businessLabelTranslation?.business_select_option}
                        onChange={(value) => handleSubSegmentOnChange(value)}
                        required
                      >
                        {subSegmentTypes?.map((segment) => (
                          <Select.Option key={segment?.businessId} value={segment?.businessId}>
                            {segment?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </React.Fragment>
            )}
            <Col span="24" className="yourbusiness-footer">
              <Button
                disabled={businessLoadingState}
                className="form-btn-link btn-back"
                type="primary"
                onClick={(e) => handleCustomerBusinessBackButton(e)}
              >
                {' '}
                <LeftOutlined />
                {businessLabelTranslation?.business_back}
              </Button>
              <span className="linkSeparator"></span>
              <Button
                loading={businessLoadingState}
                className="catelog-btn"
                type="primary"
                onClick={(e) => handleCustomerBusinessSubmit(e)}
              >
                {businessLabelTranslation?.business_continue}
                <RightOutlined />
              </Button>
            </Col>
          </React.Fragment>
        ) : (
          <Error errorLabelTranslation={businessLabelTranslation} />
        )}
      </Row>
    </Modal>
  );
};

export default BusinessForm;
