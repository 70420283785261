import { useIntercom } from 'react-use-intercom';
import { useBeforeunload } from 'react-beforeunload';
import Carousel from 'react-elastic-carousel';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Button, Form } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  loadCatalogues,
  getLabelTransaltion,
  createCustomer,
  createCustomerBusiness,
  createShippingDetails,
  createBillingDetails,
  createCustomerDelivery,
  createCustomerPickUp,
  createCustomerRewards,
} from '../actions';
import Confirmation from './Confimation';
import Timeout from './Timeout';
import {
  LANGUAGE,
  EMPTY_STRING,
  EMAIL_VALIDATION_PATTERN,
  WORKED_WITH_SYSCO_REP_TOGGLE_STYLING,
  BROWSER_TAB_ON_CLOSE_MESSAGE,
  CUSTOMER_TYPES,
  DELIVERY_WINDOW_NO_PREFERENCE,
  PURCHASE_OPTIONS,
  UTM_PARAMS,
} from '../constants/appConstants';
import Header from '../components/header';
import Footer from '../components/Footer';
import AppLoader from '../components/AppLoader';
import EmptyCatalogues from '../components/emptyCatalogues';
import validateAndAddFields from '../utils/FieldValidation';
import Item from '../item';

import ContactUsForm from './ContactUsForm';
import Catalogues from './Catalogues';
import AboutYouForm from './AboutYouForm';
import NewCustomerForm from './NewCustomerForm';
import BusinessForm from './BusinessForm';
import ShippingDetails from './ShippingDetails';
import BillingDetails from './Billing';
import RewardsAndFinish from './RewardsAndFinish';
import Complete from './Complete';
import ShippingType from './ShippingType';
import Call from './Call';
import Redirect from './Redirect';

import '../assets/css/Home.css';

const catalogueImageBaseUrl = process.env.REACT_APP_CATALOGUE_IMAGE_BASE_URL;
const TIMEOUT = process.env.REACT_APP_TIMEOUT;
const MODAL_CLOSE_LATENCY = process.env.REACT_APP_MODAL_CLOSE_LATENCY;
const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

function Home(props) {
  const { form } = props;
  const { getFieldDecorator, resetFields, validateFields } = form;

  const [homeLabelTranslation, setHomeLabelTranslation] = useState([]);
  const [language, setLanguage] = useState(LANGUAGE.ENGLISH);

  const [isCreditTerms, setIsCreditTerms] = useState(false);
  const [isSyscoRep, setIsSyscoRep] = useState(true);

  const [customerType, setCustomerType] = useState(CUSTOMER_TYPES?.SYSCO_ASSOCIATE_HELP);

  const [customerFirstName, setCustomerFirstName] = useState(EMPTY_STRING);
  const [customerLastName, setCustomerLastName] = useState(EMPTY_STRING);
  const [customerBusinessName, setCustomerBusinessName] = useState(EMPTY_STRING);
  const [hasWorkedWithSyscoRep, setHasWorkedWithSyscoRep] = useState(false);
  const [syscoAssociateName, setSyscoAssociateName] = useState(EMPTY_STRING);
  const [hasNotWorkedWithSyscoRep, setHasNotWorkedWithSyscoRep] = useState(true);
  const [customerLoadingState, setCustomerLoadingState] = useState(false);

  // UTM Params
  const { search } = useLocation();
  const [gclid, setGclid] = useState(EMPTY_STRING);
  const [utmSource, setUtmSource] = useState(EMPTY_STRING);
  const [utmMedium, setUtmMedium] = useState(EMPTY_STRING);
  const [utmCampaign, setUtmCampaign] = useState(EMPTY_STRING);
  const [utmContent, setUtmContent] = useState(EMPTY_STRING);
  const [utmUrl, setUtmUrl] = useState(EMPTY_STRING);

  const getUtmValue = (utmParams) => {
    return !utmParams ? EMPTY_STRING : utmParams;
  };

  const setUtmQueryUrl = (utmParam, value) => {
    const utmQueryData = {};
    if (value) {
      utmQueryData[`${utmParam}`] = value;
    }
    return utmQueryData;
  };

  useEffect(() => {
    const query = queryString.parse(search);
    setGclid(getUtmValue(query?.gclid));
    setUtmSource(getUtmValue(query?.utm_source));
    setUtmMedium(getUtmValue(query?.utm_medium));
    setUtmCampaign(getUtmValue(query?.utm_campaign));
    setUtmContent(getUtmValue(query?.utm_content));

    const sourceParam = setUtmQueryUrl(UTM_PARAMS?.UTM_SOURCE, query?.utm_source);
    const mediumParam = setUtmQueryUrl(UTM_PARAMS?.UTM_MEDIUM, query?.utm_medium);
    const campaignParam = setUtmQueryUrl(UTM_PARAMS?.UTM_CAMPAIGN, query?.utm_campaign);
    const contentParam = setUtmQueryUrl(UTM_PARAMS?.UTM_CONTENT, query?.utm_content);
    const gclidParam = setUtmQueryUrl(UTM_PARAMS?.GCLID, query?.gclid);

    const utmParamUrl = Object.assign(sourceParam, mediumParam, campaignParam, contentParam, gclidParam);

    if (Object.keys(utmParamUrl).length !== 0) {
      setUtmUrl(`?${String(new URLSearchParams(utmParamUrl))}`);
    }
  }, [search]);

  const handleIsCreditTerms = (event) => {
    event.preventDefault();
    setCustomerType(CUSTOMER_TYPES?.CREDIT_TERM);
    setIsCreditTerms(true);
    setIsSyscoRep(false);
  };
  const handleIsSyscoRep = (event) => {
    event.preventDefault();
    setCustomerType(CUSTOMER_TYPES?.SYSCO_ASSOCIATE_HELP);
    setIsCreditTerms(false);
    setIsSyscoRep(true);
  };

  const [closeConfirmationVisible, setCloseConfirmationVisible] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [timeoutModalVisible, setTimeoutModalVisible] = useState(false);
  const [callModalVisible, setCallModalVisible] = useState(false);
  const [redirectModalVisible, setRedirectModalVisible] = useState(false);

  const [catalogueDownloadModalVisible, setCatalogueDownloadModalVisible] = useState(false);
  const [contactUsModalVisible, setContactUsModalVisible] = useState(false);
  const [newCustomerModalVisible, setNewCustomerModalVisible] = useState(false);
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [onboardingCompletedModalVisible, setOnboardingCompletedModalVisible] = useState(false);
  const [catalogueToShow, setCatalogueToShow] = useState(EMPTY_STRING);
  const [billingHeaderVisible, setBillingHeaderVisible] = useState(false);
  const [shippingTypeModalVisible, setShippingTypeModalVisible] = useState(false);

  const [onboardingBusinessModalVisible, setOnboardingBusinessModalVisible] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState(EMPTY_STRING);
  const [selectedIndustryKey, setSelectedIndustryKey] = useState(EMPTY_STRING);
  const [subSegmentTypes, setSubSegmentTypes] = useState([]);
  const [segmentVisible, setSegmentVisible] = useState(false);
  const [subSegmentVisible, setSubSegmentVisible] = useState(false);
  const [segmentBusinessId, setSegmentBusinessId] = useState(0);
  const [businessLoadingState, setBusinessLoadingState] = useState(false);

  const [onboardingShippingModalVisible, setOnboardingShippingModalVisible] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(EMPTY_STRING);
  const [shippingCity, setShippingCity] = useState(EMPTY_STRING);
  const [shippingPostalCode, setShippingPostalCode] = useState(EMPTY_STRING);
  const [shippingProvince, setShippingProvince] = useState(EMPTY_STRING);
  const [shippingConfirm, setShippingConfirm] = useState(false);
  const [isSameBillingAddress, setIsSameBillingAddress] = useState(false);
  const [shippingLoadingState, setShippingLoadingState] = useState(false);

  const [onboardingBillingModalVisible, setOnboardingBillingModalVisible] = useState(false);
  const [billingAddress, setBillingAddress] = useState(EMPTY_STRING);
  const [billingCity, setBillingCity] = useState(EMPTY_STRING);
  const [billingPostalCode, setBillingPostalCode] = useState(EMPTY_STRING);
  const [billingProvince, setBillingProvince] = useState(EMPTY_STRING);
  const [billingConfirm, setBillingConfirm] = useState(false);
  const [billingLoadingState, setBillingLoadingState] = useState(false);

  const [onboardingRewardsModalVisible, setOnboardingRewardsModalVisible] = useState(false);
  const [agreeToSyscoRewards, setAgreeToSyscoRewards] = useState(false);
  const [agreeToSyscoTerms, setAgreeToSyscoTerms] = useState(false);
  const [rewardsOptions, setRewardsOptions] = useState(EMPTY_STRING);
  const [rewardOptionActionNo, setRewardOptionActionNo] = useState(4);
  const [termsAndConditionsVisible, setTermsAndConditionsVisible] = useState(false);
  const [syscoRewardsTermsAndConditionsVisible, setSyscoRewardsTermsAndConditionsVisible] = useState(false);
  const [rewardsLoadingState, setRewardsLoadingState] = useState(false);

  const [customerUserId, setCustomerUserId] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(EMPTY_STRING);
  const [customerPhone, setCustomerPhone] = useState(EMPTY_STRING);
  const [salesRepPostalCode, setSalesRepPostalCode] = useState(EMPTY_STRING);
  const [isSalesRepPostalCodeError, setIsSalesRepPostalCodeError] = useState(false);

  const [isDelivery, setIsdDelivery] = useState(false);
  const [isPickUp, setIsPickUp] = useState(false);
  const [shippinTypeLoadingState, setShippinTypeLoadingState] = useState(false);

  const [maxDeliveryDays, setmaxDeliveryDays] = useState(2);
  const [purchaseOption, setPurchaseOption] = useState(PURCHASE_OPTIONS?.OPTION_2);

  const [selectedDeliveryDays, setSelectedDeliveryDays] = useState([true, false, true, false, false]);
  const [selectedNumberOfDays, setSelectedNumberOfDays] = useState(2);

  const [isMonday, setIsMonday] = useState(true);
  const [isTuesday, setIsTuesday] = useState(false);
  const [isWednesday, setIsWednesday] = useState(true);
  const [isThursday, setIsThursday] = useState(false);
  const [isFriday, setIsFriday] = useState(false);

  const [isAM, setIsAM] = useState(false);
  const [isPM, setIsPM] = useState(false);
  const [isNoPreference, setIsNoPreference] = useState(true);

  const [deliveryWindow, setDeliveryWindow] = useState(DELIVERY_WINDOW_NO_PREFERENCE);

  const [customerResError, setCustomerResError] = useState(false);
  const [businessResError, setBusinessResError] = useState(false);
  const [shippingResError, setShippingResError] = useState(false);
  const [billingResError, setBillingResError] = useState(false);
  const [deliveryResError, setDeliveryResError] = useState(false);
  const [pickUpResError, setPickupResError] = useState(false);
  const [rewardsResError, setRewardsResError] = useState(false);
  const [opcoResError, setOpcoResError] = useState(false);
  const [customerResIsSuccess, setCustomerResIsSuccess] = useState(false);

  const [isBillingProvinceError, setIsBillingProvinceError] = useState(false);
  const [isBillingPostalCodeError, setIsBillingPostalCodeError] = useState(false);
  const [isBillingStreetAddressError, setIsBillingStreetAddressError] = useState(false);

  const [isRewardsOnboardingForm, setIsRewardsOnboardingForm] = useState(false);

  const handleWorkedWithSyscoRepToggleStyling = (workWithSyscoRep) => {
    if (workWithSyscoRep) {
      return WORKED_WITH_SYSCO_REP_TOGGLE_STYLING.SELETED;
    }
    return WORKED_WITH_SYSCO_REP_TOGGLE_STYLING.NOT_SELECTED;
  };

  const {
    data: { opcoId: resOpcoId, opcoName: resOpcoName, opcoAddress: resOpcoAddress, distance: resDistance },
    error: opcoError,
  } = useSelector((state) => state.opcoLocation, resOpcoName);

  const dispatch = useDispatch();
  const {
    data: { items: catalogueData },
  } = useSelector((state) => state.catalogues);

  const {
    data: { isSuccess: customerIsSuccess, userId: customerResUserId },
    error: customerError,
  } = useSelector((state) => state.customer);

  const {
    data: { isSuccess: businessIsSuccess },
    error: businessError,
  } = useSelector((state) => state.customerBusiness);

  const {
    data: { isSuccess: shippingIsSuccess },
    error: shippingError,
    fetching: shippingFetching,
  } = useSelector((state) => state.shippingDetails);

  const {
    data: { isSuccess: billingIsSuccess },
    error: billingError,
  } = useSelector((state) => state.billingDetails);

  const {
    data: { isSuccess: deliveryIsSuccess },
    error: deliveryError,
  } = useSelector((state) => state.customerDelivery);

  const {
    data: { isSuccess: pickupIsSuccess },
    error: pickUpError,
  } = useSelector((state) => state.customerPickUp);

  const {
    data: { isSuccess: rewardsIsSuccess, userId },
    error: rewardsError,
    fetching: rewardsFetching,
  } = useSelector((state) => state.customerRewards);

  const googleAnalytics = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', GOOGLE_ANALYTICS_KEY, {
      page_path: '/customer-portal/',
      cookie_domain: 'none',
    });
  };

  useEffect(() => {
    googleAnalytics();
  }, []);

  useEffect(() => {
    if (opcoError === null || opcoError === EMPTY_STRING) {
      setOpcoResError(false);
    } else {
      setOpcoResError(true);
    }
  }, [opcoError]);

  useEffect(() => {
    if (customerError) {
      setCustomerResError(customerError);
    }
  }, [customerError]);

  useEffect(() => {
    if (customerIsSuccess) {
      setCustomerResIsSuccess(customerIsSuccess);
    }
  }, [customerIsSuccess]);

  useEffect(() => {
    if (businessError) {
      setBusinessResError(businessError);
    }
  }, [businessError]);

  useEffect(() => {
    if (shippingError) {
      setShippingResError(shippingError);
    }
  }, [shippingError]);

  useEffect(() => {
    if (billingError) {
      setBillingResError(billingError);
    }
  }, [billingError]);

  useEffect(() => {
    if (deliveryError) {
      setDeliveryResError(deliveryError);
    }
  }, [deliveryError]);

  useEffect(() => {
    if (pickUpError) {
      setPickupResError(pickUpError);
    }
  }, [pickUpError]);

  useEffect(() => {
    if (rewardsError) {
      setRewardsResError(rewardsError);
    }
  }, [rewardsError]);

  useEffect(() => {
    dispatch(loadCatalogues(language));
    dispatch(getLabelTransaltion(language));
  }, [dispatch, language]);

  useEffect(() => {
    if (customerResUserId !== EMPTY_STRING) {
      setCustomerUserId(customerResUserId);
    }
  }, [customerResUserId]);

  const phoneNumberValidation = (phone) => {
    if (phone.length === 11 && phone.substring(0, 1) === '1') {
      return true;
    }
    return false;
  };

  const {
    data: { labelNames },
    fetching: labelFetching,
  } = useSelector((state) => state.labelNames);

  useEffect(() => {
    const labelKey = labelNames?.reduce((acc, curr) => {
      acc[curr.label] = curr.name;
      return acc;
    }, {});
    setHomeLabelTranslation(labelKey);
  }, [language, labelNames]);

  const { show: showIntercom } = useIntercom();

  const clearOnBoardingFormData = () => {
    setCustomerUserId(EMPTY_STRING);
    setCustomerFirstName(EMPTY_STRING);
    setCustomerLastName(EMPTY_STRING);
    setCustomerEmail(EMPTY_STRING);
    setCustomerPhone(EMPTY_STRING);
    setCustomerBusinessName(EMPTY_STRING);
    setHasWorkedWithSyscoRep(false);
    setHasNotWorkedWithSyscoRep(true);
    setSyscoAssociateName(EMPTY_STRING);
    setCustomerResIsSuccess(false);
    setSalesRepPostalCode(EMPTY_STRING);
    setIsSalesRepPostalCodeError(null);

    setSelectedIndustry(EMPTY_STRING);
    setSelectedIndustryKey(EMPTY_STRING);
    setSegmentBusinessId(0);
    setSegmentVisible(false);
    setSubSegmentVisible(false);

    setShippingAddress(EMPTY_STRING);
    setShippingCity(EMPTY_STRING);
    setShippingPostalCode(EMPTY_STRING);
    setShippingProvince(EMPTY_STRING);
    setShippingConfirm(false);
    setIsSameBillingAddress(false);
    setBillingHeaderVisible(false);
    setOpcoResError(false);

    setBillingAddress(EMPTY_STRING);
    setBillingCity(EMPTY_STRING);
    setBillingPostalCode(EMPTY_STRING);
    setBillingProvince(EMPTY_STRING);
    setBillingConfirm(false);

    setIsMonday(true);
    setIsTuesday(false);
    setIsWednesday(true);
    setIsThursday(false);
    setIsFriday(false);

    setIsAM(false);
    setIsPM(false);
    setIsNoPreference(true);
    setDeliveryWindow(DELIVERY_WINDOW_NO_PREFERENCE);

    setmaxDeliveryDays(2);
    setPurchaseOption(PURCHASE_OPTIONS?.OPTION_2);

    setSelectedDeliveryDays([true, false, true, false, false]);
    setSelectedNumberOfDays(2);

    setAgreeToSyscoRewards(false);
    setAgreeToSyscoTerms(false);
    setRewardsOptions(EMPTY_STRING);
    setRewardOptionActionNo(4);
    setSyscoRewardsTermsAndConditionsVisible(false);
    setTermsAndConditionsVisible(false);

    resetFields();

    setRewardsResError(false);

    setCustomerResError(false);
    setBusinessResError(false);
    setShippingResError(false);
    setBillingResError(false);
    setDeliveryResError(false);
    setPickupResError(false);
    setRewardsResError(false);
    setOpcoResError(false);

    setIsRewardsOnboardingForm(false);

    setCustomerLoadingState(false);
    setBusinessLoadingState(false);
    setShippingLoadingState(false);
    setBillingLoadingState(false);
    setRewardsLoadingState(false);
    setShippinTypeLoadingState(false);

    setIsBillingProvinceError(false);
    setIsBillingPostalCodeError(false);
    setIsBillingStreetAddressError(false);

    form.setFieldsValue({
      RewardsSelVal: undefined,
    });
  };

  const saveOptionalDataOnClose = () => {
    const firstName = validateAndAddFields(customerFirstName, 'firstName', null);
    const lastName = validateAndAddFields(customerLastName, 'lastName', null);
    const businessName = validateAndAddFields(customerBusinessName, 'businessName', null);
    const postalCode = isSyscoRep && validateAndAddFields(salesRepPostalCode, 'postalCode', isSalesRepPostalCodeError);
    const syscoAssociate = hasWorkedWithSyscoRep && validateAndAddFields(syscoAssociateName, 'syscoAssociate', null);
    const optionalDataOnClose = Object.assign(firstName, lastName, businessName, postalCode, syscoAssociate);
    optionalDataOnClose.workedWithSyscoAssociate = hasWorkedWithSyscoRep;
    return optionalDataOnClose;
  };

  const saveDataOnClose = () => {
    const dataOnClose = {
      userId: customerUserId,
      isModelClosed: true,
      lang: language,
    };
    setIsModalClosed(true);

    if (
      customerModalVisible &&
      ((customerEmail && customerEmail.match(EMAIL_VALIDATION_PATTERN)) || customerPhone.length === 10)
    ) {
      dataOnClose.customerOption = customerType;
      if (customerEmail && customerEmail.match(EMAIL_VALIDATION_PATTERN) && customerPhone.length === 10) {
        dataOnClose.email = customerEmail;
        dataOnClose.phone = customerPhone;
      } else if (customerEmail && customerEmail.match(EMAIL_VALIDATION_PATTERN)) {
        dataOnClose.email = customerEmail;
      } else if (customerPhone.length === 10) {
        dataOnClose.phone = customerPhone;
      }
      // Set UTM Params
      dataOnClose.gclid = gclid;
      dataOnClose.utmSource = utmSource;
      dataOnClose.utmMedium = utmMedium;
      dataOnClose.utmCampaign = utmCampaign;
      dataOnClose.utmContent = utmContent;

      const dataObject = saveOptionalDataOnClose();
      Object.assign(dataOnClose, dataObject);
      dispatch(createCustomer(dataOnClose));
    } else if (onboardingBusinessModalVisible) {
      dispatch(createCustomerBusiness(dataOnClose));
    } else if (onboardingShippingModalVisible) {
      dispatch(createShippingDetails(dataOnClose));
    } else if (onboardingBillingModalVisible) {
      dispatch(createBillingDetails(dataOnClose));
    } else if (shippingTypeModalVisible) {
      if (isDelivery) {
        dispatch(createCustomerDelivery(dataOnClose));
      } else if (isPickUp) {
        dispatch(createCustomerPickUp(dataOnClose));
      }
    } else if (onboardingRewardsModalVisible) {
      dispatch(createCustomerRewards(dataOnClose));
    }
  };

  const handleTerminateOnBoarding = _.debounce(() => {
    saveDataOnClose();

    setCloseConfirmationVisible(false);
    setNewCustomerModalVisible(false);
    setCustomerModalVisible(false);
    setOnboardingBusinessModalVisible(false);
    setOnboardingShippingModalVisible(false);
    setOnboardingBillingModalVisible(false);
    setShippingTypeModalVisible(false);
    setOnboardingRewardsModalVisible(false);
    clearOnBoardingFormData();
    setIsCreditTerms(false);
    setIsSyscoRep(true);
    setCustomerType(CUSTOMER_TYPES?.SYSCO_ASSOCIATE_HELP);
  }, MODAL_CLOSE_LATENCY);

  const handleOnIdle = (event) => {
    if (
      newCustomerModalVisible ||
      customerModalVisible ||
      onboardingBusinessModalVisible ||
      onboardingShippingModalVisible ||
      onboardingBillingModalVisible ||
      shippingTypeModalVisible ||
      onboardingRewardsModalVisible ||
      contactUsModalVisible ||
      catalogueDownloadModalVisible
    ) {
      setTimeoutModalVisible(true);
    }
  };

  useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  useBeforeunload(() => {
    if (
      !customerResIsSuccess &&
      !isCreditTerms &&
      (newCustomerModalVisible ||
        customerModalVisible ||
        onboardingBusinessModalVisible ||
        onboardingShippingModalVisible ||
        onboardingBillingModalVisible ||
        shippingTypeModalVisible ||
        onboardingRewardsModalVisible ||
        contactUsModalVisible ||
        catalogueDownloadModalVisible)
    ) {
      return BROWSER_TAB_ON_CLOSE_MESSAGE;
    }
  });

  return (
    <Layout>
      <Header
        language={language}
        setLanguage={setLanguage}
        setNewCustomerModalVisible={setNewCustomerModalVisible}
        headerLabelTranslation={homeLabelTranslation}
        utmUrl={utmUrl}
      />
      <Row className="section-2">
        <Col span={24} className="section-2-wrap">
          <h1 className="h1-home" key="home_new_customer_welcome">
            {homeLabelTranslation?.home_new_customer_welcome}
          </h1>
          <p className="p-home" key="home_register_to_view">
            {homeLabelTranslation?.home_register_to_view}
          </p>
        </Col>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card bordered={false} className="account-card-left">
              <img className="account-card-icon" src={require('../assets/Images/avatar.png')} />
              <h4 className="account-card-left-text" key="home_create_business_account">
                {homeLabelTranslation?.home_create_business_account}
              </h4>
              <p className="account-card-left-subtext" key="home_takes_time">
                {homeLabelTranslation?.home_takes_time}
              </p>
              <Button
                type="primary"
                key="home_create_business_account_2"
                className="btn-primary-account"
                onClick={() => setNewCustomerModalVisible(true)}
              >
                {homeLabelTranslation?.home_create_business_account_2}
              </Button>
            </Card>
          </Col>
        </Row>
      </Row>
      <Row className="section-3">
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="section-3-box-1">
          <div>
            <img className="homepage-section-3-item-image1" src={require('../assets/Images/qa.png')} />
            <h2 className="homepage-infobox-text" key="home_quality_assurance">
              {homeLabelTranslation?.home_quality_assurance}
            </h2>
            <p className="homepage-infobox-subtext" key="home_quality_msg">
              {homeLabelTranslation?.home_quality_msg}
            </p>
          </div>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="section-3-box-1">
          <div>
            <img className="homepage-section-3-item-image1" src={require('../assets/Images/cs.png')} />
            <h2 className="homepage-infobox-text" key="home_customer_service">
              {homeLabelTranslation?.home_customer_service}
            </h2>
            <p className="homepage-infobox-subtext" key="home_customer_service_msg">
              {homeLabelTranslation?.home_customer_service_msg}
            </p>
          </div>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="section-3-box-1">
          <div>
            <img className="homepage-section-3-item-image1" src={require('../assets/Images/ont.png')} />
            <h2 className="homepage-infobox-text" key="home_order_inventory">
              {homeLabelTranslation?.home_order_inventory}
            </h2>
            <p className="homepage-infobox-subtext" key="home_order_inventory_msg">
              {homeLabelTranslation?.home_order_inventory_msg}
            </p>
          </div>
        </Col>
      </Row>
      <Row className="section-4">
        <Col span={24}>
          <div>
            <h1 className="section-4-heading" key="home_partnership_msg">
              {homeLabelTranslation?.home_partnership_msg}
            </h1>
            <hr className="divider"></hr>
          </div>
        </Col>
        <Col span={24}>
          <h2 className="section-4-subheading" key="home_download_catalogue">
            {homeLabelTranslation?.home_download_catalogue}
          </h2>
          <div className="fixed-carousel-height">
            {catalogueData && (
              <Carousel
                itemsToShow={5}
                itemPadding={[0, 50]}
                showEmptySlots
                breakPoints={[
                  { width: 1, itemsToShow: 1 },
                  { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
                  { width: 850, itemsToShow: 3 },
                  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
                  { width: 1450, itemsToShow: 5 },
                  { width: 1750, itemsToShow: 6 },
                ]}
              >
                {catalogueData &&
                  catalogueData.map((catalogue) => {
                    const { catalogueId } = catalogue;
                    const { catalogueName } = catalogue;
                    const imageUrl = catalogueImageBaseUrl + catalogue.imageUrl;
                    return (
                      <Item key={catalogueId}>
                        <Card className="catelog-card">
                          <img className="catelog-img" src={imageUrl} />
                          <h3 className="catelogu-title">{catalogueName}</h3>
                          <a
                            className="catelog-view"
                            onClick={() => {
                              setCatalogueToShow(catalogue);
                              setCatalogueDownloadModalVisible(true);
                            }}
                          >
                            {homeLabelTranslation?.home_view}
                            <RightOutlined />
                          </a>
                        </Card>
                      </Item>
                    );
                  })}
              </Carousel>
            )}
            {(!Array.isArray(catalogueData) || !catalogueData.length) && <EmptyCatalogues />}
          </div>
        </Col>
      </Row>
      <Row gutter={16} className="section-5">
        <Col span={24}>
          <h1 className="section-5-heading" key="home_ready_customer">
            {homeLabelTranslation?.home_ready_customer}
          </h1>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="wrap-section-5-inner-1">
          <Card bordered={false} className="section-5-inner-1 section-5-inner-wrap">
            <img src={require('../assets/Images/avatar.png')} />
            <h1 className="section-5-inner-1-heading" key="home_create_business_footer">
              {homeLabelTranslation?.home_create_business_footer}
            </h1>
            <p className="footer-box1-subtext" key="home_take_time_footer">
              {homeLabelTranslation?.home_take_time_footer}
            </p>
            <Button
              className="btn-primary-account"
              type="primary"
              key="home_create_business_2_footer"
              onClick={() => setNewCustomerModalVisible(true)}
            >
              {homeLabelTranslation?.home_create_business_2_footer}
            </Button>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="wrap-section-5-inner-3">
          <Card bordered={false} className="section-5-inner-3">
            <h1 className="" key="home_got_questions">
              {homeLabelTranslation?.home_got_questions}
            </h1>
            <p className="footer-question-sub" key="home_got_answers">
              {homeLabelTranslation?.home_got_answers}
            </p>
            <div className="footer-question-item-wrap">
              <Col span={12}>
                <a className="section-5-inner-3-a" onClick={() => setCallModalVisible(true)} key="home_call">
                  <img src={require('../assets/Images/call.png')} />
                  <br />
                  <div className="footer-question-item">
                    {homeLabelTranslation?.home_call} <RightOutlined />
                  </div>
                </a>
              </Col>
              <Col span={12}>
                <a className="section-5-inner-3-a" onClick={showIntercom} key="home_chat">
                  <img src={require('../assets/Images/chat.png')} />
                  <br />
                  <div className="footer-question-item">
                    {homeLabelTranslation?.home_chat} <RightOutlined />
                  </div>
                </a>
              </Col>
            </div>
          </Card>
        </Col>
      </Row>
      <Footer footerLabelTranslation={homeLabelTranslation} />
      <NewCustomerForm
        newCustomerLabelTranslation={homeLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        newCustomerModalVisible={newCustomerModalVisible}
        setNewCustomerModalVisible={setNewCustomerModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        setCustomerModalVisible={setCustomerModalVisible}
        isCreditTerms={isCreditTerms}
        handleIsCreditTerms={handleIsCreditTerms}
        isSyscoRep={isSyscoRep}
        handleIsSyscoRep={handleIsSyscoRep}
      />
      <AboutYouForm
        aboutYouLabelTranslation={homeLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        customerUserId={customerUserId}
        isSyscoRep={isSyscoRep}
        isCreditTerms={isCreditTerms}
        billingHeaderVisible={billingHeaderVisible}
        setNewCustomerModalVisible={setNewCustomerModalVisible}
        customerModalVisible={customerModalVisible}
        setCustomerModalVisible={setCustomerModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        setOnboardingRewardsModalVisible={setOnboardingRewardsModalVisible}
        setOnboardingCompletedModalVisible={setOnboardingCompletedModalVisible}
        setOnboardingBusinessModalVisible={setOnboardingBusinessModalVisible}
        setCustomerEmail={setCustomerEmail}
        setCustomerPhone={setCustomerPhone}
        setHasWorkedWithSyscoRep={setHasWorkedWithSyscoRep}
        customerPhone={customerPhone}
        customerEmail={customerEmail}
        clearOnBoardingFormData={clearOnBoardingFormData}
        isModalClosed={isModalClosed}
        setIsModalClosed={setIsModalClosed}
        customerType={customerType}
        phoneNumberValidation={phoneNumberValidation}
        handleWorkedWithSyscoRepToggleStyling={handleWorkedWithSyscoRepToggleStyling}
        hasWorkedWithSyscoRep={hasWorkedWithSyscoRep}
        setCustomerFirstName={setCustomerFirstName}
        setCustomerLastName={setCustomerLastName}
        setCustomerBusinessName={setCustomerBusinessName}
        setSyscoAssociateName={setSyscoAssociateName}
        setHasNotWorkedWithSyscoRep={setHasNotWorkedWithSyscoRep}
        customerFirstName={customerFirstName}
        customerLastName={customerLastName}
        customerBusinessName={customerBusinessName}
        syscoAssociateName={syscoAssociateName}
        hasNotWorkedWithSyscoRep={hasNotWorkedWithSyscoRep}
        customerResError={customerResError}
        setRedirectModalVisible={setRedirectModalVisible}
        setCustomerLoadingState={setCustomerLoadingState}
        customerLoadingState={customerLoadingState}
        getFieldDecorator={getFieldDecorator}
        form={form}
        setCustomerResIsSuccess={setCustomerResIsSuccess}
        validateFields={validateFields}
        isSalesRepPostalCodeError={isSalesRepPostalCodeError}
        setIsSalesRepPostalCodeError={setIsSalesRepPostalCodeError}
        salesRepPostalCode={salesRepPostalCode}
        setSalesRepPostalCode={setSalesRepPostalCode}
        gclid={gclid}
        utmSource={utmSource}
        utmMedium={utmMedium}
        utmCampaign={utmCampaign}
        utmContent={utmContent}
      />
      <BusinessForm
        businessLabelTranslation={homeLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        onboardingBusinessModalVisible={onboardingBusinessModalVisible}
        setOnboardingBusinessModalVisible={setOnboardingBusinessModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        isSyscoRep={isSyscoRep}
        billingHeaderVisible={billingHeaderVisible}
        setSelectedIndustryKey={setSelectedIndustryKey}
        setSegmentVisible={setSegmentVisible}
        setSubSegmentVisible={setSubSegmentVisible}
        setSelectedIndustry={setSelectedIndustry}
        setCustomerModalVisible={setCustomerModalVisible}
        segmentBusinessId={segmentBusinessId}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        setOnboardingShippingModalVisible={setOnboardingShippingModalVisible}
        setOnboardingCompletedModalVisible={setOnboardingCompletedModalVisible}
        setOnboardingRewardsModalVisible={setOnboardingRewardsModalVisible}
        clearOnBoardingFormData={clearOnBoardingFormData}
        selectedIndustryKey={selectedIndustryKey}
        segmentVisible={segmentVisible}
        subSegmentVisible={subSegmentVisible}
        subSegmentTypes={subSegmentTypes}
        setSegmentBusinessId={setSegmentBusinessId}
        selectedIndustry={selectedIndustry}
        setSubSegmentTypes={setSubSegmentTypes}
        businessIsSuccess={businessIsSuccess}
        businessResError={businessResError}
        setBusinessLoadingState={setBusinessLoadingState}
        businessLoadingState={businessLoadingState}
        form={form}
        getFieldDecorator={getFieldDecorator}
      />
      <ShippingDetails
        shippingLabelTranslation={homeLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        setShippingProvince={setShippingProvince}
        shippingProvince={shippingProvince}
        shippingConfirm={shippingConfirm}
        shippingPostalCode={shippingPostalCode}
        shippingCity={shippingCity}
        shippingAddress={shippingAddress}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        isSameBillingAddress={isSameBillingAddress}
        setIsPickUp={setIsPickUp}
        setIsdDelivery={setIsdDelivery}
        setOnboardingShippingModalVisible={setOnboardingShippingModalVisible}
        setBillingHeaderVisible={setBillingHeaderVisible}
        setRewardOptionActionNo={setRewardOptionActionNo}
        setOnboardingBillingModalVisible={setOnboardingBillingModalVisible}
        setOnboardingBusinessModalVisible={setOnboardingBusinessModalVisible}
        setShippingTypeModalVisible={setShippingTypeModalVisible}
        onboardingShippingModalVisible={onboardingShippingModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        setShippingAddress={setShippingAddress}
        setShippingCity={setShippingCity}
        setShippingPostalCode={setShippingPostalCode}
        setIsSameBillingAddress={setIsSameBillingAddress}
        setShippingConfirm={setShippingConfirm}
        billingHeaderVisible={billingHeaderVisible}
        shippingResError={shippingResError}
        opcoResError={opcoResError}
        setShippingLoadingState={setShippingLoadingState}
        shippingLoadingState={shippingLoadingState}
        setOpcoResError={setOpcoResError}
        setShippingResError={setShippingResError}
        shippingFetching={shippingFetching}
        shippingIsSuccess={shippingIsSuccess}
        form={form}
        getFieldDecorator={getFieldDecorator}
      />
      <BillingDetails
        billingLabelTranslation={homeLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        onboardingBillingModalVisible={onboardingBillingModalVisible}
        setOnboardingBillingModalVisible={setOnboardingBillingModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        billingHeaderVisible={billingHeaderVisible}
        billingAddress={billingAddress}
        setBillingAddress={setBillingAddress}
        billingCity={billingCity}
        setBillingCity={setBillingCity}
        setBillingPostalCode={setBillingPostalCode}
        billingPostalCode={billingPostalCode}
        billingProvince={billingProvince}
        billingConfirm={billingConfirm}
        setBillingConfirm={setBillingConfirm}
        setBillingProvince={setBillingProvince}
        setOnboardingShippingModalVisible={setOnboardingShippingModalVisible}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        billingIsSuccess={billingIsSuccess}
        billingResError={billingResError}
        setShippingTypeModalVisible={setShippingTypeModalVisible}
        setBillingLoadingState={setBillingLoadingState}
        billingLoadingState={billingLoadingState}
        form={form}
        getFieldDecorator={getFieldDecorator}
        isProvinceError={isBillingProvinceError}
        setIsProvinceError={setIsBillingProvinceError}
        isPostalCodeError={isBillingPostalCodeError}
        setIsPostalCodeError={setIsBillingPostalCodeError}
        isStreetAddressError={isBillingStreetAddressError}
        setIsStreetAddressError={setIsBillingStreetAddressError}
      />
      <RewardsAndFinish
        rewardAndFinishLabelTranslation={homeLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        setRewardsOptions={setRewardsOptions}
        setOnboardingRewardsModalVisible={setOnboardingRewardsModalVisible}
        setShippingTypeModalVisible={setShippingTypeModalVisible}
        agreeToSyscoRewards={agreeToSyscoRewards}
        agreeToSyscoTerms={agreeToSyscoTerms}
        rewardsOptions={rewardsOptions}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        setOnboardingCompletedModalVisible={setOnboardingCompletedModalVisible}
        clearOnBoardingFormData={clearOnBoardingFormData}
        onboardingRewardsModalVisible={onboardingRewardsModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        rewardOptionActionNo={rewardOptionActionNo}
        billingHeaderVisible={billingHeaderVisible}
        setAgreeToSyscoRewards={setAgreeToSyscoRewards}
        setAgreeToSyscoTerms={setAgreeToSyscoTerms}
        setTermsAndConditionsVisible={setTermsAndConditionsVisible}
        termsAndConditionsVisible={termsAndConditionsVisible}
        setSyscoRewardsTermsAndConditionsVisible={setSyscoRewardsTermsAndConditionsVisible}
        syscoRewardsTermsAndConditionsVisible={syscoRewardsTermsAndConditionsVisible}
        rewardsIsSuccess={rewardsIsSuccess}
        rewardsResError={rewardsResError}
        setRewardsLoadingState={setRewardsLoadingState}
        rewardsLoadingState={rewardsLoadingState}
        getFieldDecorator={getFieldDecorator}
        rewardsFetching={rewardsFetching}
        isRewardsOnboardingForm={isRewardsOnboardingForm}
        setIsRewardsOnboardingForm={setIsRewardsOnboardingForm}
        form={form}
        resOpcoId={resOpcoId}
        userId={userId}
        isHomePage
      />
      <Complete
        completeLabelTranslation={homeLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        onboardingCompletedModalVisible={onboardingCompletedModalVisible}
        setOnboardingCompletedModalVisible={setOnboardingCompletedModalVisible}
        hasWorkedWithSyscoRep={hasWorkedWithSyscoRep}
        isCreditTerms={isCreditTerms}
        isSyscoRep={isSyscoRep}
        setIsCreditTerms={setIsCreditTerms}
        setIsSyscoRep={setIsSyscoRep}
        setCustomerType={setCustomerType}
      />
      <ShippingType
        shippingTypeLabelTranslation={homeLabelTranslation}
        language={language}
        setLanguage={setLanguage}
        setIsMonday={setIsMonday}
        setIsTuesday={setIsTuesday}
        setIsWednesday={setIsWednesday}
        setIsThursday={setIsThursday}
        setIsFriday={setIsFriday}
        setSelectedNumberOfDays={setSelectedNumberOfDays}
        setSelectedDeliveryDays={setSelectedDeliveryDays}
        setIsAM={setIsAM}
        setIsPM={setIsPM}
        setIsNoPreference={setIsNoPreference}
        setmaxDeliveryDays={setmaxDeliveryDays}
        selectedNumberOfDays={selectedNumberOfDays}
        selectedDeliveryDays={selectedDeliveryDays}
        maxDeliveryDays={maxDeliveryDays}
        isMonday={isMonday}
        isTuesday={isTuesday}
        isWednesday={isWednesday}
        isThursday={isThursday}
        isFriday={isFriday}
        isPM={isPM}
        isAM={isAM}
        isNoPreference={isNoPreference}
        resDistance={resDistance}
        resOpcoName={resOpcoName}
        resOpcoAddress={resOpcoAddress}
        setIsPickUp={setIsPickUp}
        setIsdDelivery={setIsdDelivery}
        customerResUserId={customerResUserId}
        isSameBillingAddress={isSameBillingAddress}
        billingHeaderVisible={billingHeaderVisible}
        isDelivery={isDelivery}
        isPickUp={isPickUp}
        customerUserId={customerUserId}
        isModalClosed={isModalClosed}
        resOpcoId={resOpcoId}
        shippingTypeModalVisible={shippingTypeModalVisible}
        setShippingTypeModalVisible={setShippingTypeModalVisible}
        setOnboardingShippingModalVisible={setOnboardingShippingModalVisible}
        setOnboardingBillingModalVisible={setOnboardingBillingModalVisible}
        setOnboardingRewardsModalVisible={setOnboardingRewardsModalVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        deliveryIsSuccess={deliveryIsSuccess}
        pickupIsSuccess={pickupIsSuccess}
        pickUpResError={pickUpResError}
        deliveryResError={deliveryResError}
        setShippinTypeLoadingState={setShippinTypeLoadingState}
        shippinTypeLoadingState={shippinTypeLoadingState}
        deliveryWindow={deliveryWindow}
        setDeliveryWindow={setDeliveryWindow}
        purchaseOption={purchaseOption}
        setPurchaseOption={setPurchaseOption}
      />
      <Catalogues
        catalogueLabelTranslation={homeLabelTranslation}
        language={language}
        catalogueDownloadModalVisible={catalogueDownloadModalVisible}
        setCatalogueDownloadModalVisible={setCatalogueDownloadModalVisible}
        catalogueToShow={catalogueToShow}
        catalogueImageBaseUrl={catalogueImageBaseUrl}
        gclid={gclid}
        utmSource={utmSource}
        utmMedium={utmMedium}
        utmCampaign={utmCampaign}
        utmContent={utmContent}
      />
      <ContactUsForm
        contactUsLabelTranslation={homeLabelTranslation}
        contactUsModalVisible={contactUsModalVisible}
        setContactUsModalVisible={setContactUsModalVisible}
        gclid={gclid}
        utmSource={utmSource}
        utmMedium={utmMedium}
        utmCampaign={utmCampaign}
        utmContent={utmContent}
        language={language}
      />
      <Confirmation
        closeLabelTranslation={homeLabelTranslation}
        closeConfirmationVisible={closeConfirmationVisible}
        setCloseConfirmationVisible={setCloseConfirmationVisible}
        handleTerminateOnBoarding={handleTerminateOnBoarding}
      />
      <Timeout
        timeoutLabelTranslation={homeLabelTranslation}
        setTimeoutModalVisible={setTimeoutModalVisible}
        timeoutModalVisible={timeoutModalVisible}
        handleTerminateOnBoarding={handleTerminateOnBoarding}
        setCallModalVisible={setCallModalVisible}
      />
      <Call
        timeoutLabelTranslation={homeLabelTranslation}
        setCallModalVisible={setCallModalVisible}
        callModalVisible={callModalVisible}
        handleTerminateOnBoarding={handleTerminateOnBoarding}
      />
      <Redirect
        setRedirectModalVisible={setRedirectModalVisible}
        redirectModalVisible={redirectModalVisible}
        redirectLabelTranslation={homeLabelTranslation}
      />
      {labelFetching ? <AppLoader /> : null}
    </Layout>
  );
}

export default Form.create()(Home);
